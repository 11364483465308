<template>
<div class="home-component">
  <div class="background-image">
    <img src="@/assets/map.svg" />
    <div class="text">
      <div class="text-inner">
        <div class="main">
          Your own Wireguard VPN server, made simple
        </div>
        <div class="sub">
          Wireshield is a graphical interface for your Wireguard VPN server
          to easily manage users and connections
        </div>
        <div class="button-area">
          <a class="button" @click="$router.push('/docs/')">Try Wireshield</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .background-image {
    width: 100%;
    overflow-x: hidden;
    height: 500px;
    position: relative;
    background: #FFC000;
    background: radial-gradient(circle, rgba(82,8,190, 0.7) 20%,
    rgba(0, 0, 0, 0.4) 100%);
    border-bottom: 4px solid #333;

    & > img {
      opacity: 0.4;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }

    .text {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      color: white;
    }

    .text-inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 700px;
    }

    .text .main {
      font-weight: bold;
      font-size: 24px;
    }

    .text .sub {
      font-size: 16px;
      display: inline-block;
      padding: 10px 0;
    }

    .text .button-area {
      margin-top: 30px;

      .button {
        margin-top: 10px;
        padding: 10px 22px;
        font-size: 15px;
        background: #FFC000;
        color: #2C2C2C;
        box-shadow: 0px 2px 10px rgba(0,0,0,0.1);

        &:hover {
          background: #e6ad00;
        }
      }
    }

    .text-image {
      width: 50%;
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 100%;
      }
    }

  }

  .home-component {
    background: #FFC000;
    color: white;
  }

@media only screen and (max-width: 762px) {
  .text-inner {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }
}
</style>
