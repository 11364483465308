<template>
  <div>
    <div class="license-navigation">
      <div class="container">
        <div class="menu clearfix">
          <router-link class="left" to="/license-manager">My Licenses</router-link>
          <router-link class="left" to="/license-manager/store">Store</router-link>

          <router-link class="right" to="/logout">Logout</router-link>
          <router-link class="right" to="/license-manager/profile">Profile</router-link>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<style scoped lang="scss">
.license-navigation {
  height: 40px;
  background: #bd95191a;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  line-height: 40px;
  // border-bottom: 1px solid #bd9519a8;
  background: linear-gradient(90deg,
  rgba(255,192,0, 0.2) 0%,
  rgba(169,100,95, 0.2) 30%,
  rgba(140,69,127, 0.2) 50%,
  rgba(169,100,95, 0.2) 70%,
  rgba(255,192,0, 0.2) 100%);
  margin-top: -1px;

  .menu a {
    color: #bd9519;
    margin-right: 50px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
