<template>
<div>
  <p>
    Wireshield is installed on your system in the
    <code class="inline">/opt/wireshield</code> directory on your system. The
    most interesting file here is the configuration file that is stored at
    <code class="inline">/opt/wireshield/config/wireshield.ini</code>. With this
    file you are able to change configurations that you can't change in the
    web interface.
  </p>
  <hr class="hr">
  <h2>The configuration structure</h2>
  <p>
    The configuration file listed below contains the default settings for
    Wireshield. Settings that you would like to leave default may be omitted. Be
    sure you include the right ini-file <code class="inline">[header]</code>.
  </p>
    <code class="preview wrap">
    [general]<br>
    <div># Running with two workers is recommended but can tweak this as you like</div>
    workers = 2<br>
    <div># The application will listen on both IPv6 and IPv4 by default.</div>
    ipv6_enabled = true<br>
<br>
    [logging]<br>
    <div># Wireshield will log to syslog. The default log level is 'info'</div>
    level = "info"<br>
<br>
    [http]<br>
    <div># The default HTTP port used by the application</div>
    port = 3000<br>
<br>
    [https]<br>
    <div># By default https is disabled</div>
    enabled = false<br>
    <div># Once https is enabled it will run on port 3443 by default</div>
    port = 3443<br>
    <div># The certificate and private keys can be configured here. A small catch
        is that the path is relative to the /opt/wireshield/bin directory</div>
    cert_file = "" <span># E.g.: '../keys/cert.pem'</span><br>
    key_file = "" <span># E.g.: '../keys/key.pem'</span><br>
<div># By default a client requesting the http version will be redirected to https</div>
    force_https = true<br>
<br>
    [ldap]<br>
    <div># On premium installations LDAP support can be enabled. Users will be
        synchronized if it is enabled</div>
    enabled = false<br>
    server_host = "" <span># E.g.: 'ldap://127.0.0.1:389'</span><br>
    enable_tls = false<br>
    verify_tls = false<br>
    base_dn = "" <span># E.g.: 'dc=dev,dc=org,dc=en'</span><br>
    <div># The manager credentials for the synchronization</div>
    manager_dn = "" <span># E.g.: 'cn=admin,dc=dev,dc=org,dc=en'</span><br>
    manager_password = ""<br>
    user_filter = "" <span># E.g.: 'cn=*'</span><br>
    object_class = "" <span># E.g.: 'inetOrgPerson'</span><br>
<br>
    [ldap.bind]<br>
    <div># Wireshield tries to synchronize the name and email address from LDAP
      but you need to tell the system which attributes it has to look for</div>
    name = "" <span># E.g.: 'cn'</span><br>
    email = "" <span># E.g.: 'mail'</span>
  </code>
  <hr class="hr">
  <h2>Restarting the server</h2>
  <p>
    After changing the configuration file you will need to restart wireshield. You
    can do this with the following command:
  </p>
  <code class="preview">
    sudo systemctl restart wireshield
  </code>
  <hr class="hr">
  <h2>Checking the logs</h2>
  <p>
    If something is wrong after changing the configuration or you need to check
    if your change worked, it is good to know where to find the logs. To tail the
    logs use <code class="inline">journalctl</code>.
  </p>
  <code class="preview">
    sudo journalctl -fu wireshield
  </code>
</div>
</template>
<style lang="scss" scoped>
    p {
        line-height: 1.8;
        font-size: 14px;
        color: #999;
    }

    code.preview.wrap {
        white-space: initial;
    }

    code.preview > div {
        color: teal;
    }
    code.preview > span {
      color: teal;
      opacity: 0.5;
    }
</style>
