<template>
  <div class="pricing-holder">
    <div class="container">
      <div class="header">
        Wireshield Pricing
        <div class="smaller">Use it for free at home</div>
      </div>
      <div>
        <div class="tiers">
          <div class="tier free-tier">
            <div class="tier-card">
              <div class="tier-header">
                Personal
                <div class="price">Free</div>
              </div>
              <div class="features">
                <div class="tier-feature">
                  <i class="uil uil-check"></i> 3 Connections</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Two factor authentication</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Insights in data usage</div>
                <div class="tier-feature fade">
                  <i class="uil uil-times"></i> Additional connections support</div>
                <div class="tier-feature fade">
                  <i class="uil uil-times"></i> LDAP support</div>
              </div>
              <div class="tier-action">
                <a class="button wide" @click="$router.push('/docs/')">Start using Wireshield</a>
              </div>
            </div>
          </div>

          <div class="tier free-tier">
            <div class="tier-card coming-soon">
              <div class="tier-header">
                Premium
                <div class="price">&euro; 12,- per year</div>
              </div>
              <div class="features">
                <div class="tier-feature">
                  <i class="uil uil-check"></i> 20 Connections</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Two factor authentication</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Insights in data usage</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Supports additional connections</div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> LDAP support</div>
              </div>
              <div class="tier-action">
              <a class="button wide">Buy a license</a>
              </div>
            </div>
          </div>

          <div class="tier free-tier">
            <div class="tier-card coming-soon">
              <div class="tier-header">
                Additional
                <div class="price">&euro; 2,- per year</div>
              </div>
              <div class="features">
                <div class="tier-feature">
                  <i class="uil uil-exclamation-octagon"></i> Requires a premium license
                </div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> +1 Connection
                </div>
                <div class="tier-feature">
                  <i class="uil uil-check"></i> Can be ordered in bulk
                </div>
              </div>
              <div class="tier-action">
                <a class="button wide">Buy a license</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: white;
  padding: 30px 0 15px;

  .smaller {
    font-size: 22px;
    opacity: 0.4;
  }
}

.tiers {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
}

.tier {
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  float: left;
  text-align: left;

  .tier-card {
    background: #2c2c2c;
    padding: 30px;
    box-shadow: 0px 4px 30px rgba(0,0,0,0.2);
    padding-bottom: 90px;
    position: relative;
    border: 1px solid #474747;

    &.coming-soon {
      position: relative;
      overflow: hidden;

      &::after {
        position: absolute;
        display: block;
        content: 'Coming soon!';
        padding: 6px 10px;
        width: 100%;
        transform: rotate(45deg);
        text-align: center;
        right: -30%;
        border-top: 1px solid #daa608;
        border-bottom: 1px solid #daa608;
        background: #ffc00063;
        color: #daa608;
        top: 10%;
        font-size: 16px;
        box-shadow: 0 0 black;
      }

      & > * {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .tier-header {
      font-weight: bold;
      font-size: 28px;
      color: white;

      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #eee;

      .price {
        font-size: 16px;
        color: #D89F00;
      }
    }

    .tier-feature {
      font-size: 15px;
      padding: 4px 0;
      padding-left: 26px;

      i {
        margin-left: -26px;
      }

      &.fade {
        opacity: 0.3;
      }
    }
  }
}

.tier-action {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;

  a {
    display: block;
    width: auto;
    text-align: center;
    background: #FFC000;
    color: #2C2C2C;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);

      &:hover {
        background: #e6ad00;
      }
  }
}

.pricing-holder {
  margin: 50px 0 50px;
}

hr.large {
  margin: 60px 0;
  border: none;
  border-top: 1px solid #eee;
}

@media only screen and (max-width: 762px) {
  .tiers {
    display: block;

    .tier {
      display: block;
      float: none;
      padding: 0;
      padding-bottom: 20px;
    }
  }
}
</style>
