<template>
  <div class="usp-holder">
    <div class="container">
      <div class="usps clearfix">
        <div class="usp clearfix">
          <div class="usp-icon">
            <i class="uil uil-shield"></i>
            <!-- <img src="@/assets/shield.svg" /> -->
          </div>
          <div class="usp-content">
            <div class="usp-title">Built for security</div>
            <div class="usp-text">
              Enable two factor authentication to secure your account.
            </div>
          </div>
        </div>
        <div class="usp clearfix">
          <div class="usp-icon">
            <i class="uil uil-server-network-alt"></i>
            <!-- <img src="@/assets/contacts.svg" /> -->
          </div>
          <div class="usp-content">
            <div class="usp-title">Integrate with LDAP</div>
            <div
              class="usp-text">
              This optional feature let's you sync users from your own database.
            </div>
          </div>
        </div>
        <div class="usp clearfix">
          <div class="usp-icon">
            <i class="uil uil-qrcode-scan"></i>
            <!-- <img src="@/assets/download.svg" /> -->
          </div>
          <div class="usp-content">
            <div class="usp-title">Easy to use</div>
            <div class="usp-text">Import your connection profile by scanning the QR code.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.usp-holder {
  background: #2c2c2c;
  background: linear-gradient(90deg,
  rgba(255, 192, 0, 0.2) 0%,
  rgba(169, 100, 95, 0.2) 30%,
  rgba(140, 69, 127, 0.2) 50%,
  rgba(169, 100, 95, 0.2) 70%,
  rgba(255, 192, 0, 0.2) 100%);
  padding: 70px 0;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;

  .usp {
    box-sizing: border-box;
    width: 33%;
    padding: 20px;
    position: relative;
    float: left;

    .usp-icon {
      float: left;
      width: 50px;
      font-size: 40px;
      color: #D89F00;
    }

    .usp-content {
      width: calc(100% - 50px);
      box-sizing: border-box;
      padding-left: 17px;
      float: left;
    }

    .usp-title {
      font-weight: bold;
      color: white;
      font-size: 20px;
      padding-bottom: 6px;
    }
  }
}

@media only screen and (max-width: 762px) {
  .usp-holder {
    padding: 40px;

    .usp {
      width: auto;
      display: block;
      float: none;
    }
  }
}
</style>
