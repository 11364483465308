import Vuex from 'vuex';
import { Wireshield } from '@/types/wireshield';

export default new Vuex.Store({
  state: {
    loggedIn: false,
    shoppingCart: [] as Wireshield.CartLicense[],
  },
  mutations: {
    loginStatus(state, val) {
      state.loggedIn = val;
    },
    setShoppingCart(state, newShoppingCart: Wireshield.CartLicense[]) {
      state.shoppingCart = newShoppingCart;
    },
    addToShoppingCart(state, newItem: Wireshield.CartLicense) {
      state.shoppingCart.push(newItem);
    },
  },
  actions: {},
  modules: {},
});
