<template>
<div>
  <p>
    Setting up Wireshield is more personal than installing the application.
    The right setup that suits your needs depends on your situation
    and use case. In the following steps you will configure your first user and
    define the properties of the VPN server and Wireshield installation. To
    start, browse to port <code class="inline">:3000</code> on your Wireshield
    device.
  </p>
  <hr class="hr">
  <h2>Step 1: Create the first administrator user</h2>
  <p>
    Your system will prompt you to create your first user. This part of the
    configuration is most intuitive. Simply fill in the necessary fields and make
    sure to use a strong password.
  </p>
  <hr class="hr">
  <h2>Step 2: Create your VPN server</h2>
  <h3>Name</h3>
  <p>
    This can be anything that makes sense to you. It can be your address, company
    name or maybe the domain name for the VPN location you want to access. But
    whatever you use here, keep in mind that this will be used to name the
    connection profiles which can be downloaded by your clients.
  </p>

  <h3>VPN Network Range</h3>
  <p>
    This is the range in which the server is allowed to handout IP-addresses. Since
    Wireguard uses static IP-addresses, you should choose a range that currently
    doesn't have any clients. A network range has this format:
    <code class="inline">[IP-address]/[netmask]</code>. For example: 192.168.2.1/24.
    Here, the IP-address will not only indicate the range, but also be the static
    IP-address of the VPN server itself.
    <a target="_blank" href="http://jodies.de/ipcalc">IP-calculators on the internet</a>
    might help you choosing the network range that works for you.
  </p>

  <h3>VPN Listen Port</h3>
  <p>
    This is the port where your VPN server will be active. The default port for a
    Wireguard VPN server is: 51820. But feel free to choose another port.
  </p>

  <h3>VPN External Address</h3>
  <p>
    The VPN server lives both on your local network, and on the internet. This external
    address is the address used on the internet to point to your VPN server. If you install
    Wireshield on your Raspberry Pi at home you can use your home IP-address. On the
    internet, there are
    <a href="https://ipecho.net/" target="_blank" >websites</a> that can help you
    find your own outgoing IP-address. If you install it on a large server on the
    internet, use the IP-address or hostname of that server.
  </p>

  <h3>Outgoing network interface</h3>
  <p>
    You system uses one default interface to communicate with the outside world, but
    might have more interfaces installed. Here choose the outgoing interface that is
    used by your system to communicate with the internet. On a Raspberry Pi with
    RaspberryOS this is most likely <code class="inline">eth0</code>.
  </p>
  <hr class="hr">
  <h2>Step 3: Setup port forwarding</h2>
  <p>
    This step is different for every router, but the general goal is the same.
    You will need to tell your router that an incoming (UDP) VPN signal from
    the internet is allowed, and needs to be forwarded to your newly setup VPN server.
    Most routers have the option to enable port forwarding.
  </p>
  <hr class="hr">
  <h2>Step 4: You are good to go!</h2>
  <p>
    At this point every is set up. You should be able to create a
    connection profile and connect to your VPN network.
  </p>
</div>
</template>
<style lang="scss" scoped>
    p {
        line-height: 1.8;
        font-size: 14px;
        color: #999;
    }
</style>
