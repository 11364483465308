import axios, { AxiosInstance } from 'axios';
import format from 'date-fns/format';

export type JWTClaims = {
  sub: number;
  email: string;
  access: number;
  exp: number;
  iat: number;
  nbf: number;
};
export default class Util {
  private readonly httpDefaults = {
    timeout: 5000,
    http401RedirectTo: '/logout',
    tokenKey: 'jwt',
  };

  public getJWT(): JWTClaims | null {
    const token = window.localStorage.getItem(this.httpDefaults.tokenKey);
    if (token && token !== 'null') {
      try {
        const base64 = token.split('.')[1];
        const json = atob(base64);
        return JSON.parse(json) as JWTClaims;
      } catch (e) {
        console.warn(e);
        return null;
      }
    }

    return null;
  }

  public http(): AxiosInstance {
    const httpClient = axios.create();
    const token = window.localStorage.getItem(this.httpDefaults.tokenKey);
    httpClient.defaults.timeout = this.httpDefaults.timeout * 3;

    // Intercept a 401 response and redirect to the logout/login page.
    httpClient.interceptors.response.use(undefined, (err) => {
      const error = err.response;
      if (error && error.status === 401) {
        window.location.href = this.httpDefaults.http401RedirectTo;
        return;
      }

      throw err;
    });

    if (token !== null && httpClient.defaults.headers) {
      (httpClient.defaults.headers as any)['X-Auth-Token'] = token;
    }

    return httpClient;
  }

  public formatDateTime(dateString: string): string {
    return format(new Date(dateString), 'PPpp');
  }

  public formatDate(dateString: string): string {
    return format(new Date(dateString), 'PPP');
  }

  public getLicenseType(licenseType: number): string {
    return ['Lifetime', 'Premium', 'Additional'][licenseType];
  }
}

const util = new Util();
export { util };
