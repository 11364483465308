<template>
  <div>
    <div class="title-header">
      <div class="container">
        <h1 v-if="$route.name === 'Install'">Wireshield Installation</h1>
        <h1 v-if="$route.name === 'Setup'">Setup Wireshield</h1>
        <h1 v-if="$route.name === 'Configure'">Configure Wireshield</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 docs">
          <router-view></router-view>
        </div>
        <div class="col-md-3">
          <h2>Docs</h2>
          <div class="mini-menu">
            <router-link to="/docs/">Installation</router-link>
            <router-link to="/docs/setup">Setup</router-link>
            <router-link to="/docs/configure">Configure</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mini-menu {
  a {
    display: block;
    padding: 6px 10px;
    color: #BBB;
    text-decoration: none;

    &.router-link-exact-active, &:hover {
      // text-decoration: underline;
      color: white;
      background: #333;
    }
  }
}
</style>
