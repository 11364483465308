import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Docs from '../views/Docs.vue';
import LicenseManager from '../views/LicenseManager.vue';
import Licenses from '../views/LicenseManager/Licenses.vue';
import LicenseView from '../views/LicenseManager/LicenseView.vue';
import Profile from '../views/LicenseManager/Profile.vue';
import Store from '../views/LicenseManager/Store.vue';
import CheckoutSummary from '../views/LicenseManager/CheckoutSummary.vue';
import Generate from '../views/LicenseManager/Generate.vue';

import Login from '../views/Auth/Login.vue';
import Logout from '../views/Auth/Logout.vue';
import Register from '../views/Auth/Register.vue';

import Installation from '../components/Docs/Install.vue';
import Setup from '../components/Docs/Setup.vue';
import Configure from '../components/Docs/Configure.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    children: [
      {
        name: 'Install',
        path: '',
        component: Installation,
      },
      {
        name: 'Setup',
        path: 'setup',
        component: Setup,
      },
      {
        name: 'Configure',
        path: 'configure',
        component: Configure,
      },
    ],
  },
  {
    path: '/license-manager',
    name: 'LicenseManager',
    component: LicenseManager,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'licenseOverview',
        component: Licenses,
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'license/:id',
        name: 'licenseView',
        component: LicenseView,
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'store',
        name: 'store',
        component: Store,
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'summary',
        name: 'checkout-summary',
        component: CheckoutSummary,
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'generate',
        name: 'generate',
        component: Generate,
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'profile',
        component: Profile,
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.authRequired) {
    const jwt = window.localStorage.getItem('jwt');
    if (!jwt || jwt === 'null') {
      return next('/logout');
    }
    const jwtData = JSON.parse(
      atob(jwt.split('.')[1]),
    );
    if (jwtData.exp * 1000 < Date.now()) {
      return next('/logout');
    }
  }

  if (to.meta?.adminRequired) {
    const jwt = window.localStorage.getItem('jwt');
    if (!jwt || jwt === 'null') {
      return next('/logout');
    }
    const jwtData = JSON.parse(
      atob(jwt.split('.')[1]),
    );
    if (jwtData.exp * 1000 < Date.now()) {
      return next('/logout');
    }

    if (jwtData.access < 100) {
      return next('/');
    }
  }
  return next();
});

export default router;
