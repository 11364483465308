<template>
<div>
  <p>
      This installation guide assumes you are known with executing commands in
      the terminal. Later on, you will have to setup port forwarding in your
      router. This works different for every router, but the general steps
      are explained in this guide. This guide is made for Debian-based Linux
      systems. An installation for a Raspberry Pi is highlighted to help home
      users up to speed.
  </p>
  <hr class="hr">
  <h2>Step 1: Install Wireguard</h2>
  <p>
    First of all you will have to install Wireguard. This is the VPN
    server that handles your VPN traffic. The installation of this component
    differs among systems. This has to do with that Wireguard is included
    in the Linux kernel from version 5.6+. Some systems run on lower versions
    but support backported versions of Wireguard.
  </p>
  <h3>On a Raspberry Pi (32-bit Raspberry OS)</h3>
  <p>
    On a Debian-based Raspberry Pi you will have to install the kernel
    headers to enable Wireguard kernel support.
  </p>
  <code class="preview">
    sudo apt-get update<br>
    sudo apt-get install raspberrypi-kernel-headers
  </code>
  <p>
    At the time of writing Wireguard is not available in the stable repository.
    The unstable Debian repository does contain Wireguard. In the next part you
    will add the unstable repository to your system:
  </p>
  <code class="preview">
    echo "deb http://deb.debian.org/debian/ unstable main" |
    sudo tee --append /etc/apt/sources.list.d/unstable.list<br>
    sudo apt-key adv --keyserver keyserver.ubuntu.com --recv-keys 04EE7237B7D453EC<br>
    printf 'Package: *\nPin: release a=unstable\nPin-Priority: 150\n' |
    sudo tee --append /etc/apt/preferences.d/limit-unstable<br>
  </code>
  <p>
      With all this done, we can now install wireguard with apt-get:
  </p>
  <code class="preview">
    sudo apt-get update<br>
    sudo apt-get install wireguard
  </code>
  <h3>On general Linux environments</h3>
  <p>
      Modern distributions will already contain Wireguard in their
      repositories. For most systems this is as easy as:
  </p>
  <code class="preview">
    sudo apt-get update<br>
    sudo apt-get install wireguard
  </code>
  <hr class="hr">
  <h2>Step 2: Install Wireshield</h2>
  <p>
      Wireshield can be installed on Linux devices with ARM, ARM64 and
      AMD64 architectures. It depends on <code class="inline">iptables</code>,
      <code class="inline">systemd</code> and <code class="inline">wireguard</code>.
      Make sure you have these programs installed before you install Wireshield.
  </p>
  <h3>Installing Wireshield on ARM32 (RaspberryOS)</h3>
  <code class="preview">
      curl -sLO https://get.wireshield.io/script/install-arm.sh<br>
      sudo bash install-arm.sh
  </code>
  <h3>Installing Wireshield on ARM64</h3>
  <code class="preview">
      curl -sLO https://get.wireshield.io/script/install-aarch64.sh<br>
      sudo bash install-aarch64.sh
  </code>
  <h3>Installing Wireshield on AMD64</h3>
  <code class="preview">
      curl -sLO https://get.wireshield.io/script/install-x86_64.sh<br>
      sudo bash install-x86_64.sh
  </code>
  <hr class="hr">
  <h2>Step 3: You're done!</h2>
  <p>
      At this point Wireshield is installed and running on port 3000. You
      can now configure your installation in the browser by browsing
      to <code class="inline">http://[IP of device]:3000</code>. Need help
      setting up your Wireshield installation?
      <router-link to="/docs/setup" class="link">
      Click here to go to the setup guide
      </router-link>.
  </p>
</div>
</template>
<style lang="scss" scoped>
    p {
        line-height: 1.8;
        font-size: 14px;
        color: #999;
    }
</style>
